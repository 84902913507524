<template>
    <EventTicketsOrder
        type="member"
        :member-id="id"
        :columns="[
            { key: 'venue', label: 'Venue', sortable: false },
            { key: 'event', label: 'Event', sortable: false, class: 'text-center' },
            { key: 'bookingStatus', label: 'Status', sortable: false, class: 'text-center' },
            { key: 'totalTicketsPrice', label: 'Total', sortable: false, class: 'text-center' },
            { key: 'guestsCount', label: 'Guests', sortable: false, class: 'text-center' },
            { key: 'created_at', label: 'Received', sortable: true, class: 'text-center' },
        ]"
    />
</template>

<script>
import EventTicketsOrder from '@/components/venue/EventTicketsOrder'

export default {

    name: 'MemberTickets',

    props: ['id'],

    components: {
        EventTicketsOrder,
    },
}
</script>
